import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { expSettleAction } from "./state/expenseSlice";
import { useSelector } from "react-redux";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";

function ExpenseSettle() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);

  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const loggedId = useSelector((state) => state.auth.empId);
  const current_date = moment(Date()).format("DD-MM-YYYY");
  const docNumber2 = useSelector((state) => state.expSettle.docNumber);
  const reqId = useSelector((state) => state.expSettle.reqId);
  const settleFor = useSelector((state) => state.expSettle.settleFor);
  const [data, setData] = useState([]);
  const [docNumber, setDocNumber] = useState(docNumber2);
  const [resType, setResType] = useState("Material");
  const [requestQty, setRequestQty] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [vat, setVat] = useState(0);
  const [tot, setTot] = useState(0);
  const [tax, setTax] = useState(0);
  const [pension, setPension] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [data2, setData2] = useState([]);
  const [totalExpense, setTotalExpense] = useState(0);
  const [remainQty, setRemainQty] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState([]);
  const [employee, setEmployee] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "> Expense > Settle";

    if (reqId) {
      axios
        .post("https://www.bdc.et/bdc-api/get_expense_request2.php", {
          req_id: reqId,
        })
        .then((res) => {
          setData(res.data);
          setRemainQty(res.data[0][11]);
          setUnitPrice(res.data[0][5]);
          setResType(res.data[0][12]);
        })
        .catch((err) => alert(err));
    }

    if (docNumber != "") {
      setIsLoading3(true);
      axios
        .post("https://www.bdc.et/bdc-api/get_expense_settle.php", {
          doc_number: docNumber,
        })
        .then((res) => setData2(res.data))
        .catch((err) => alert(err))
        .finally(() => setIsLoading3(false));
    }

    if (settleFor != "") {
      axios
        .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
          empId: settleFor,
        })
        .then((res) =>
          setEmployee(
            res.data[0][1] + " " + res.data[0][2] + " " + res.data[0][3]
          )
        )
        .catch((err) => alert(err));
    }
  }, []);

  const OpenDialog = () => {
    setIsLoading2(true);
    setOpenDialog(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_employee_list.php", {})
      .then((res) => setDialog(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  };

  const OpenDocNumber = () => {
    setIsLoading3(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_expense_settle.php", {
        doc_number: docNumber,
      })
      .then((res) => setData2(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading3(false));

    dispatch(
      expSettleAction.setDocNumber({
        docNumber: docNumber,
      })
    );
  };

  const CompareQty = () => {
    if (parseFloat(requestQty) > parseFloat(remainQty)) {
      document.getElementById("requestQty").style.borderColor = "red";
      document.getElementById("requestQtyErr").style.display = "block";
    } else {
      document.getElementById("requestQty").style.borderColor = "lightgray";
      document.getElementById("requestQtyErr").style.display = "none";
    }
  };

  const RegiBtn = () => {
    if (docNumber == "") {
      document.getElementById("docNumber").style.borderColor = "red";
    } else {
      document.getElementById("docNumber").style.borderColor = "lightgray";
    }
    if (requestQty == "") {
      document.getElementById("requestQty").style.borderColor = "red";
    } else {
      document.getElementById("requestQty").style.borderColor = "lightgray";
    }
    if (settleFor == "") {
      document.getElementById("employeeErr").style.display = "block";
    } else {
      document.getElementById("employeeErr").style.display = "none";
    }

    if (
      parseFloat(requestQty) != 0 &&
      parseFloat(requestQty) <= parseFloat(remainQty) &&
      docNumber != "" &&
      settleFor != ""
    ) {
      setIsLoading(true);
      setIsLoading3(true);

      axios
        .post("https://www.bdc.et/bdc-api/post_expense_settle.php", {
          reqId: reqId,
          proName: data[0][2],
          proId: data[0][14],
          resName: data[0][3],
          resId: data[0][15],
          resType: data[0][12],
          resUnit: data[0][4],
          unitPrice: data[0][5],
          requestQty: requestQty,
          docNumber: docNumber,
          reqDocNumber: data[0][7],
          rvNumber: data[0][10],
          settleFor: settleFor,
          remainQty: parseFloat(data[0][11]) - parseFloat(requestQty),
          vat: vat,
          tot: tot,
          withHold:
            parseFloat(totalPrice) > 10000 ? parseFloat(totalPrice) * 0.02 : 0,
          tax: tax,
          pension: pension,
          settledBy: loggedId,
        })
        .then((res) => {
          dispatch(
            expSettleAction.setReqId({
              reqId: "",
            })
          );

          setData2(res.data);

          document.getElementById("resourceCont").style.display = "none";
        })
        .catch((err) => alert(err))
        .finally(() => {
          setIsLoading(false);
          setIsLoading3(false);
        });
    }
  };

  const DeleteReq = (id, reqId, docNumber) => {
    if (reqId) {
      axios
        .post("https://www.bdc.et/bdc-api/post_delete_expense_settle.php", {
          id: id,
          reqId: reqId,
          docNumber: docNumber,
        })
        .then((res) => setData2(res.data))
        .catch((err) => alert(err));
    }
  };

  return (
    <>
      {tab.includes("tab42") && (
        <>
          {openDialog ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{
                    maxHeight: "600px",
                    overflowY: "auto",
                    width: "500px",
                  }}
                >
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <h2 style={{ marginLeft: "20px", color: "gray" }}>
                      Employee List
                    </h2>
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn1"
                      style={{
                        width: "70px",
                        alignSelf: "right",
                        margin: "20px auto",
                        float: "right",
                      }}
                      onClick={() => setOpenDialog(false)}
                    />
                  </div>

                  {isLoading2 ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading2}
                        alt="loading..."
                        style={{ width: "50px", opacity: "0.3" }}
                      />
                    </div>
                  ) : (
                    <>
                      {dialog != "" ? (
                        dialog.map((diag) => (
                          <div
                            className="brdr-btm list-hover"
                            style={{ padding: "10px 20px", cursor: "pointer" }}
                            key={diag[0]}
                            onClick={() => {
                              setEmployee(
                                diag[1] + " " + diag[2] + " " + diag[3]
                              );
                              setOpenDialog(false);
                              dispatch(
                                expSettleAction.setSettleFor({
                                  settleFor: diag[0],
                                })
                              );
                              document.getElementById(
                                "employeeErr"
                              ).style.display = "none";
                            }}
                          >
                            <p style={{ margin: 0, color: "black" }}>
                              {diag[1] + " " + diag[2] + " " + diag[3]}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p style={{ marginLeft: "40px" }}>No record</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {/**Main contaianer==================================================================================== */}
          <div
            className="cont-all"
            style={{
              width: "100%",
              minWidth: "500px",
              height: "97%",
              paddingTop: "0",
              overflow: "auto",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "45px",
                backgroundColor: "gray",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top: 0,
              }}
            >
              <h2
                style={{
                  margin: "0",
                  color: "white",
                  borderColor: "white",
                  borderRight: "solid",
                  borderWidth: "1px",
                  padding: "10px 20px",
                }}
              >
                Settle Expense
              </h2>
              <p style={{ color: "whitesmoke", marginLeft: "20px" }}>
                Date: {current_date}
              </p>
            </div>
            <br />
            <div
              className="brdr-btm"
              style={{
                width: "100%",
                marginTop: "0",
                paddingTop: "10px",
                backgroundColor: "rgb(245,245,245)",
                display: "inline-flex",
              }}
            >
              <label
                style={{
                  margin: "20px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "gray",
                  width: "25%",
                }}
              >
                Document Number
                <br />
                <input
                  className="txt-input"
                  id="docNumber"
                  type="text"
                  defaultValue={docNumber2}
                  onChange={(e) => {
                    setDocNumber(e.target.value);
                  }}
                  onBlur={OpenDocNumber}
                  placeholder="Enter Document Number"
                />
              </label>
              <label
                style={{
                  margin: "20px",
                  fontSize: "14px",
                  marginLeft: "0px",
                  fontWeight: "bold",
                  color: "gray",
                  width: "25%",
                }}
              >
                Settle for
                <br />
                <p
                  id="employeeErr"
                  style={{
                    fontSize: "14px",
                    color: "red",
                    display: "none",
                    marginBottom: "0",
                    fontWeight: "normal",
                  }}
                >
                  *This information is required
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    display: employee != "" ? "block" : "none",
                    fontWeight: "normal",
                    color: "black",
                    marginBottom: "0px",
                  }}
                >
                  {employee}
                </p>
                <input
                  className="btn2"
                  id="settleFor"
                  type="button"
                  defaultValue={docNumber2}
                  onClick={OpenDialog}
                  value={employee ? "Change Employee" : "Select Employee"}
                />
              </label>
              <label
                style={{
                  margin: "20px",
                  fontSize: "14px",
                  marginLeft: "0",
                  fontWeight: "bold",
                  color: "gray",
                  width: "25%",
                }}
              >
                Total Settlement Cost <br />
                <input
                  className="txt-input"
                  id="totalCost"
                  type="text"
                  placeholder="Total Settlement (Br)"
                  value={
                    data2
                      .reduce((currnetTotal, list) => {
                        return currnetTotal + list[5] * list[6];
                      }, 0)
                      .toLocaleString() + " Br"
                  }
                  disabled
                />
              </label>
              <input
                type="button"
                id="register"
                value={reqId == "" ? "Select Request" : "Change Request"}
                className="btn1"
                style={{ width: "25%", height: "37px", marginTop: "41px" }}
                onClick={() => navigate("/requested_expense_list", {})}
              />
            </div>
            <div
              className="brdr-btm"
              id="resourceCont"
              style={{
                width: "100%",
                display: reqId ? "block" : "none",
                backgroundColor: "rgb(245,245,245)",
              }}
            >
              <label
                style={{
                  maxWidth: "350px",
                  margin: "20px 20px 10px 20px",
                  padding: "0",
                  display: "inline-flex",
                  fontWeight: "bold",
                  color: "gray",
                  fontSize: "14px",
                }}
              >
                Project:
                <p
                  style={{
                    margin: "0 10px",
                    padding: "0",
                    fontWeight: "normal",
                    color: "black",
                  }}
                >
                  {data != "" ? data[0][2] : ""}
                </p>
              </label>
              <label
                style={{
                  maxWidth: "350px",
                  margin: "0 20px",
                  padding: "0",
                  display: "inline-flex",
                  fontWeight: "bold",
                  color: "gray",
                  fontSize: "14px",
                }}
              >
                Resource:
                <p
                  style={{
                    margin: "0 10px",
                    padding: "0",
                    fontWeight: "normal",
                    color: "black",
                  }}
                >
                  {data != "" ? data[0][3] : ""}
                </p>
              </label>
              {resType == "Material" || resType == "Equip & Machine" ? (
                <label
                  style={{
                    maxWidth: "50px",
                    margin: "10px",
                    marginRight: "40px",
                    padding: "0",
                    display: "inline-flex",
                    fontWeight: "bold",
                    color: "gray",
                    fontSize: "14px",
                  }}
                >
                  Unit:
                  <p
                    style={{
                      margin: "0 10px",
                      padding: "0",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data != "" ? data[0][4] : ""}
                  </p>
                </label>
              ) : (
                ""
              )}

              <label
                style={{
                  maxWidth: "180px",
                  margin: "10px",
                  marginRight: "20px",
                  padding: "0",
                  display: "inline-flex",
                  fontWeight: "bold",
                  color: "gray",
                  fontSize: "14px",
                }}
              >
                {resType == "Material" || resType == "Equip & Machine"
                  ? "Unsettled Qty"
                  : "Unsettled Amt"}
                <p
                  style={{
                    margin: "0 10px",
                    padding: "0",
                    fontWeight: "normal",
                    color: "black",
                  }}
                >
                  {data != "" ? parseFloat(remainQty).toLocaleString() : ""}
                </p>
              </label>
              <br />
              <div style={{ display: reqId ? "inline-flex" : "none" }}>
                {resType == "Material" || resType == "Equip & Machine" ? (
                  <>
                    {/* Material container ========================================================================================= */}
                    <div style={{ display: "block" }}>
                      <p
                        id="requestQtyErr"
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "red",
                          fontSize: "14px",
                          paddingLeft: "20px",
                          display: "none",
                        }}
                      >
                        *Over Quantity
                      </p>

                      <input
                        className="txt-input"
                        id="requestQty"
                        type="number"
                        style={{ width: "180px", marginLeft: "20px" }}
                        onChange={(e) => {
                          setRequestQty(
                            e.target.value == "" ? 0 : e.target.value
                          );
                          setTotalPrice(
                            parseFloat(
                              e.target.value == "" ? 0 : e.target.value
                            ) *
                              parseFloat(
                                parseFloat(unitPrice) +
                                  parseFloat(vat) +
                                  parseFloat(tot)
                              )
                          );
                        }}
                        onBlur={CompareQty}
                        placeholder="Enter Settlement Quantity"
                      />
                    </div>
                    <div
                      style={{
                        display: "block",
                        width: "300px",
                        marginLeft: "20px",
                      }}
                    >
                      <label
                        style={{ fontSize: "14px", verticalAlign: "center" }}
                      >
                        Unit Price
                        <input
                          className="txt-input"
                          id="unitPrice"
                          type="text"
                          style={{ width: "150px", marginLeft: "39px" }}
                          value={parseFloat(unitPrice).toLocaleString() + " Br"}
                          defaultValue={0}
                          disabled
                        />
                      </label>
                      <br />
                      <label
                        style={{
                          fontSize: "14px",
                          verticalAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        VAT 15%
                        <input
                          type="checkbox"
                          style={{ margin: "0 5px" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setVat(parseFloat(unitPrice) * 0.15);
                              document.getElementById("vat").value =
                                parseFloat(unitPrice) *
                                  (0.15).toLocaleString() +
                                " Br";

                              setTotalPrice(
                                parseFloat(requestQty) *
                                  parseFloat(
                                    parseFloat(unitPrice) +
                                      parseFloat(parseFloat(unitPrice) * 0.15) +
                                      parseFloat(tot)
                                  )
                              );
                            } else {
                              setVat(0);
                              document.getElementById("vat").value = 0 + " Br";

                              setTotalPrice(
                                parseFloat(requestQty) *
                                  parseFloat(
                                    parseFloat(unitPrice) + parseFloat(tot)
                                  )
                              );
                            }
                          }}
                        />
                        <input
                          className="txt-input"
                          id="vat"
                          type="text"
                          style={{ width: "150px", marginLeft: "20px" }}
                          placeholder="Vat"
                          defaultValue={0 + " Br"}
                          disabled
                        />
                      </label>
                      <br />
                      <label
                        style={{
                          fontSize: "14px",
                          verticalAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        TOT 2%
                        <input
                          type="checkbox"
                          style={{ margin: "0 5px 0 10px" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setTot(parseFloat(unitPrice) * 0.02);
                              document.getElementById("tot").value =
                                parseFloat(unitPrice) *
                                  (0.02).toLocaleString() +
                                " Br";

                              setTotalPrice(
                                parseFloat(requestQty) *
                                  parseFloat(
                                    parseFloat(unitPrice) +
                                      parseFloat(vat) +
                                      parseFloat(unitPrice) * 0.02
                                  )
                              );
                            } else {
                              setTot(0);
                              document.getElementById("tot").value = 0 + " Br";

                              setTotalPrice(
                                parseFloat(requestQty) *
                                  parseFloat(
                                    parseFloat(unitPrice) + parseFloat(vat)
                                  )
                              );
                            }
                          }}
                        />
                        <input
                          className="txt-input"
                          id="tot"
                          type="text"
                          style={{ width: "150px", marginLeft: "20px" }}
                          defaultValue={0 + " Br"}
                          disabled
                        />
                      </label>
                    </div>
                    <div
                      style={{
                        display: "block",
                        width: "300px",
                        marginLeft: "20px",
                      }}
                    >
                      <label
                        style={{ fontSize: "14px", verticalAlign: "center" }}
                      >
                        Total Price
                        <input
                          className="txt-input"
                          id="totalPrice"
                          type="text"
                          style={{ width: "150px", marginLeft: "23px" }}
                          value={
                            parseFloat(totalPrice).toLocaleString() + " Br"
                          }
                          defaultValue={0}
                          disabled
                        />
                      </label>
                      <br />
                      <label
                        style={{ fontSize: "14px", verticalAlign: "center" }}
                      >
                        Withhold 2%
                        <input
                          className="txt-input"
                          id="unitPrice"
                          type="text"
                          style={{ width: "150px", marginLeft: "10px" }}
                          onChange={() => {}}
                          value={
                            parseFloat(totalPrice) > 10000
                              ? (
                                  parseFloat(totalPrice) * 0.02
                                ).toLocaleString() + " Br"
                              : "0 Br"
                          }
                          defaultValue={0}
                          disabled
                        />
                      </label>
                      <br />
                      <label
                        style={{ fontSize: "14px", verticalAlign: "center" }}
                      >
                        Cash
                        <input
                          className="txt-input"
                          id="unitPrice"
                          type="text"
                          style={{ width: "150px", marginLeft: "56px" }}
                          value={
                            parseFloat(
                              parseFloat(totalPrice) -
                                parseFloat(
                                  totalPrice > 10000
                                    ? parseFloat(totalPrice) * 0.02
                                    : 0
                                )
                            ).toLocaleString() + " Br"
                          }
                          defaultValue={0}
                          disabled
                        />
                      </label>
                    </div>
                    {isLoading ? (
                      <>
                        <div style={{ marginLeft: "20px" }}>
                          <img
                            src={loading1}
                            alt="login..."
                            style={{ width: "80px" }}
                          />
                        </div>
                      </>
                    ) : (
                      <input
                        type="button"
                        id="register"
                        value="Settle"
                        className="btn1"
                        style={{ margin: "5px 10px", width: "100px" }}
                        onClick={RegiBtn}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {/**labor table ==================================================================================== */}
                    <div style={{ display: "block" }}>
                      <p
                        id="requestQtyErr"
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "red",
                          fontSize: "14px",
                          paddingLeft: "20px",
                          display: "none",
                        }}
                      >
                        *Over Amount
                      </p>

                      <input
                        className="txt-input"
                        id="requestQty"
                        type="number"
                        style={{ width: "180px", marginLeft: "20px" }}
                        onChange={(e) => {
                          setRequestQty(
                            e.target.value == "" ? 0 : e.target.value
                          );
                          setTotalPrice(
                            parseFloat(
                              e.target.value == "" ? 0 : e.target.value
                            ) +
                              parseFloat(tax) +
                              parseFloat(pension)
                          );
                        }}
                        onBlur={CompareQty}
                        placeholder="Enter Settlement Amount"
                      />
                    </div>
                    <div
                      style={{
                        display: "block",
                        width: "300px",
                        marginLeft: "20px",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "14px",
                          verticalAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        Tax
                        <input
                          className="txt-input"
                          id="tax"
                          type="number"
                          style={{ width: "150px", marginLeft: "48px" }}
                          placeholder="Enter Tax (Optional)"
                          onChange={(e) => {
                            setTax(e.target.value == "" ? 0 : e.target.value);
                            setTotalPrice(
                              parseFloat(requestQty) +
                                parseFloat(
                                  e.target.value == "" ? 0 : e.target.value
                                ) +
                                parseFloat(pension)
                            );
                          }}
                        />
                      </label>

                      <br />
                      <label
                        style={{
                          fontSize: "14px",
                          verticalAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        Pension
                        <input
                          className="txt-input"
                          id="pension"
                          type="number"
                          style={{ width: "150px", marginLeft: "20px" }}
                          placeholder="Enter Pension (Optional)"
                          onChange={(e) => {
                            setPension(
                              e.target.value == "" ? 0 : e.target.value
                            );
                            setTotalPrice(
                              parseFloat(requestQty) +
                                parseFloat(tax) +
                                parseFloat(
                                  e.target.value == "" ? 0 : e.target.value
                                )
                            );
                          }}
                        />
                      </label>
                      <br />
                    </div>
                    <div style={{ display: "block", width: "300px" }}>
                      <label
                        style={{ fontSize: "14px", verticalAlign: "center" }}
                      >
                        Total Amount
                        <input
                          className="txt-input"
                          id="totalPrice"
                          type="text"
                          style={{ width: "150px", marginLeft: "20px" }}
                          value={
                            parseFloat(totalPrice).toLocaleString() + " Br"
                          }
                          defaultValue={0}
                          disabled
                        />
                      </label>
                    </div>
                    <input
                      type="button"
                      id="register"
                      value="Settle"
                      className="btn1"
                      style={{ margin: "5px 10px", width: "100px" }}
                      onClick={RegiBtn}
                    />
                  </>
                )}
              </div>
            </div>
            {/**Bottom table ==================================================================================== */}

            <table
              style={{
                width: "100%",
                minWidth: "600px",
                marginTop: "20px",
              }}
            >
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "gray",
                  position: "sticky",
                  top: "45px",
                }}
              >
                <th>Project</th>
                <th>Resource</th>
                <th>Unit</th>
                <th>Unit Price</th>
                <th>Setteld Qty</th>
                <th>Settled Amt</th>
                <th>Doc No</th>
                <th>Settle Date</th>
                <th></th>
                <th></th>
              </tr>
              {isLoading ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data2 != [] &&
                    data2.map((list) => (
                      <tr key={list[0]}>
                        <td> {list[2]}</td>
                        <td> {list[3]}</td>
                        <td> {list[4]}</td>
                        <td> {parseFloat(list[5]).toLocaleString()}</td>
                        <td> {parseFloat(list[6]).toLocaleString()}</td>
                        <td>
                          {" "}
                          {(
                            parseFloat(list[5]) * parseFloat(list[6])
                          ).toLocaleString()}
                        </td>
                        <td> {list[7]}</td>
                        <td> {moment(list[10]).format("DD-MM-YYYY")}</td>
                        <td>
                          {list[13] == empId && (
                            <FontAwesomeIcon
                              className="menu-icon"
                              id="dlt-icon"
                              style={{
                                padding: "3px",
                                cursor: "pointer",
                                margin: "0",
                                padding: 0,
                                fontSize: "14px",
                                color: "gray",
                              }}
                              icon={faTrashCan}
                              onClick={() =>
                                DeleteReq(list[0], list[1], docNumber)
                              }
                            />
                          )}
                        </td>
                        <td>
                          <input
                            style={{ cursor: "pointer" }}
                            type="button"
                            value="Open"
                            onClick={() => {
                              navigate("/expense_settle_detail", {
                                state: {
                                  id: list[0],
                                },
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </table>
          </div>
        </>
      )}
    </>
  );
}
export default ExpenseSettle;
