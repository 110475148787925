import React, { useState, useEffect } from "react";
import logo from "../img/logo2.png";

function Navbar() {
  const [windowTitle, setWindowTitle] = useState("");

  return (
    <div className="nav-cont" style={{ display: "inline-flex" }}>
      <img src={logo} className="logo-pic" alt="logo" />
      <div>
        <h1 style={{ color: "white", margin: "3px 10px 0 10px" }}>
          BDC CONSTRUCTION PLC
        </h1>
        <p
          id="windowTitle"
          style={{
            marginLeft: "20px",
            marginTop: "0px",
            color: "white",
            fontSize: "14px",
          }}
          onChange={(e) => {
            setWindowTitle(e.target.value);
          }}
        >
          {windowTitle}
        </p>
      </div>
    </div>
  );
}

export default Navbar;
