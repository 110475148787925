import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";

function NewProject() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const tab = useSelector((state) => state.auth.tab);
  const loggedId = useSelector((state) => state.auth.empId);
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [client, setClient] = useState("");
  const [consultant, setConsultant] = useState("");
  const [contractAmount, setContractAmount] = useState(0);
  const [contractDuration, setContractDuration] = useState(0);
  const [signDate, setSignDate] = useState("");
  const [handoverDate, setHandoverDate] = useState("");
  const [displayBox, setDisplayBox] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "> Project > Create New";

    setIsLoading2(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_project_list.php", {})
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));

    setDisplayBox(0);
  }, []);

  const RegiBtn = () => {
    if (name == "") {
      document.getElementById("nameErr").style.display = "block";
      document.getElementById("name").style.borderColor = "red";
    } else {
      document.getElementById("nameErr").style.display = "none";
      document.getElementById("name").style.borderColor = "lightgray";
    }
    if (location == "") {
      document.getElementById("locationErr").style.display = "block";
      document.getElementById("location").style.borderColor = "red";
    } else {
      document.getElementById("locationErr").style.display = "none";
      document.getElementById("location").style.borderColor = "lightgray";
    }
    if (client == "") {
      document.getElementById("clientErr").style.display = "block";
      document.getElementById("client").style.borderColor = "red";
    } else {
      document.getElementById("clientErr").style.display = "none";
      document.getElementById("client").style.borderColor = "lightgray";
    }
    if (contractAmount == "0") {
      document.getElementById("contractAmountErr").style.display = "block";
      document.getElementById("contractAmount").style.borderColor = "red";
    } else {
      document.getElementById("contractAmountErr").style.display = "none";
      document.getElementById("contractAmount").style.borderColor = "lightgray";
    }

    if (name != "" && location != "" && client != "" && contractAmount != 0) {
      setIsLoading(true);
      setIsLoading2(true);
      axios
        .post("https://www.bdc.et/bdc-api/post_new_project.php", {
          name: name,
          location: location,
          client: client,
          consultant: consultant,
          contractAmount: contractAmount,
          contractDuration: contractDuration,
          signDate: signDate,
          handoverDate: handoverDate,
          regBy: loggedId,
        })
        .then((res) => {
          setData(res.data);
          setDisplayBox(1);
          setName("");
          setLocation("");
          setClient("");
          setConsultant("");
          setContractAmount(0);
          setContractDuration(0);
          setSignDate("");
          setHandoverDate("");
          document.getElementById("name").value = "";
          document.getElementById("location").value = "";
          document.getElementById("client").value = "";
          document.getElementById("consultant").value = "";
          document.getElementById("contractAmount").value = "";
          document.getElementById("contractDuration").value = "";
          document.getElementById("signDate").value = "";
          document.getElementById("handoverDate").value = "";
          document.getElementById("successMsg").style.display = "block";
        })
        .catch((err) => alert(err))
        .finally(() => {
          setIsLoading(false);
          setIsLoading2(false);
        });
    } else {
      document.getElementById("successMsg").style.display = "none";
    }
  };

  return (
    <>
      {tab.includes("tab21") && (
        <>
          <div
            style={{
              display: "inline-flex",
              width: "80%",
              minWidth: "600px",
              height: "97%",
            }}
          >
            <div
              className="cont-all"
              style={{ width: "50%", height: "97%", overflowY: "auto" }}
            >
              <h2
                className="pa-h"
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  margin: 0,
                  padding: "5px 0px",
                  width: "100%",
                  position: "sticky",
                  top: 0,
                }}
              >
                Project Information
              </h2>
              <p
                id="successMsg"
                style={{
                  display: "none",
                  color: "green",
                  marginTop: "10px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Created Successfully!{" "}
              </p>
              <div style={{ width: "100%" }} id="regCont">
                <div className="cont2">
                  <p className="reg-err" id="nameErr">
                    *This Information is required!
                  </p>
                  <input
                    className="txt-input"
                    id="name"
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("location").focus();
                      }
                    }}
                    placeholder="Enter Project Name"
                    required
                  />
                  <br />
                  <p className="reg-err" id="locationErr">
                    *This Information is required!
                  </p>
                  <input
                    className="txt-input"
                    id="location"
                    type="text"
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("client").focus();
                      }
                    }}
                    placeholder="Enter Project Location"
                    required
                  />
                  <br />
                  <p className="reg-err" id="clientErr">
                    *This Information is required!
                  </p>
                  <input
                    className="txt-input"
                    id="client"
                    type="text"
                    onChange={(e) => {
                      setClient(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("consultant").focus();
                      }
                    }}
                    placeholder="Enter Client"
                    required
                  />
                  <br />
                  <input
                    className="txt-input"
                    id="consultant"
                    type="text"
                    onChange={(e) => {
                      setConsultant(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("contractAmount").focus();
                      }
                    }}
                    placeholder="Enter Consultant (Optional)"
                  />
                  <br />
                  <p className="reg-err" id="contractAmountErr">
                    *This Information is required!
                  </p>
                  <input
                    className="txt-input"
                    id="contractAmount"
                    type="text"
                    onChange={(e) => {
                      parseFloat(e.target.value)
                        ? setContractAmount(parseFloat(e.target.value))
                        : setContractAmount(0);
                    }}
                    onBlur={() => {
                      parseFloat(contractAmount)
                        ? (document.getElementById("contractAmount").value =
                            parseFloat(contractAmount).toLocaleString())
                        : (document.getElementById("contractAmount").value =
                            "");
                    }}
                    onFocus={() =>
                      contractAmount
                        ? (document.getElementById("contractAmount").value =
                            contractAmount.toString().replace(",", ""))
                        : ""
                    }
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("contractDuration").focus();
                      }
                    }}
                    placeholder="Enter Contract Amount (Birr)"
                    required
                  />
                  <br />
                  <input
                    className="txt-input"
                    id="contractDuration"
                    type="number"
                    onChange={(e) => {
                      setContractDuration(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        document.getElementById("signDate").focus();
                      }
                    }}
                    placeholder="Enter Contract Duration (Day)"
                    required
                  />
                  <br />
                  <label>
                    Contract Sign Date
                    <input
                      className="txt-input"
                      id="signDate"
                      type="date"
                      onChange={(e) => {
                        setSignDate(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          document.getElementById("handoverDate").focus();
                        }
                      }}
                    />
                  </label>
                  <br />
                  <label>
                    Site Handover Date
                    <input
                      className="txt-input"
                      id="handoverDate"
                      type="date"
                      onChange={(e) => {
                        setHandoverDate(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          document.getElementById("register").click();
                        }
                      }}
                    />
                  </label>
                  {isLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading1}
                        alt="login..."
                        style={{ width: "100px" }}
                      />
                    </div>
                  ) : (
                    <input
                      type="button"
                      id="register"
                      value="Register"
                      className="btn1"
                      style={{ margin: "20px 0", width: "150px" }}
                      onClick={RegiBtn}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="brdr-all"
              style={{
                width: "50%",
                marginLeft: "10px",
                height: "97%",
                backgroundColor: "white",
                overflowY: "auto",
              }}
            >
              <h2
                className="pa-h"
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  margin: 0,
                  padding: "5px 0px",
                  width: "100%",
                  position: "sticky",
                  top: 0,
                }}
              >
                Project List
              </h2>
              {isLoading2 ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data != [] &&
                    data.map((list) => (
                      <div
                        className="brdr-btm list-hover"
                        key={list[0]}
                        style={{ padding: "10px 20px", cursor: "pointer" }}
                        onClick={() =>
                          navigate("/project_detail", {
                            state: {
                              id: list[0],
                            },
                          })
                        }
                      >
                        <h3 style={{ margin: 0, color: "gray" }}>{list[1]}</h3>
                        <p style={{ margin: 0 }}>{list[2]}</p>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NewProject;
