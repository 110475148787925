import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import moment from "moment";
import loading2 from "../img/loading2.gif";

function ExpenseSettleDetail() {
  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  const [deleteWindow, setDeleteWindow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.getElementById("windowTitle").textContent =
      "> Expense > Settlement Detail";

    setIsLoading(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_expense_settle2.php", {
        id: id,
      })
      .then((res) => {
        setData(res.data[0]);

        axios
          .post("https://www.bdc.et/bdc-api/get_employee_id.php", {
            empId: res.data[0][13],
          })
          .then((res2) => {
            setData2(res2.data[0]);
          })
          .catch((err) => alert(err));
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  }, []);

  const DeleteValue = () => {
    if (data[0] != "") {
      axios
        .post("https://www.bdc.et/bdc-api/post_delete_expense_settle.php", {
          id: data[0],
          reqId: data[1],
          docNumber: data[7],
        })
        .then(navigate("/settle_expense", {}))
        .catch((err) => alert(err));
    }
  };

  return (
    <>
      {tab.includes("tab42") && (
        <>
          {deleteWindow ? (
            <>
              <div className="dialog-ext"></div>
              <div style={{ width: "70%", zIndex: 2, position: "absolute" }}>
                <div
                  className="dialog-cont"
                  style={{ marginTop: "60px", textAlign: "center" }}
                >
                  <p
                    id="deleteVerify"
                    style={{
                      margin: "10px ",
                      padding: 0,
                      color: "red",
                      fontSize: "14px",
                      paddingLeft: "20px",
                    }}
                  >
                    *Are you sure you want to delete this settlement? This
                    action cannot be undo!
                  </p>
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <input
                      type="button"
                      id="edit"
                      value="Delete"
                      className="btn1"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={DeleteValue}
                    />
                    <input
                      type="button"
                      id="register"
                      value="Cancel"
                      className="btn2"
                      style={{
                        margin: "20px",
                        width: "70px",
                        alignSelf: "right",
                      }}
                      onClick={() => setDeleteWindow(false)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div
            className="cont-all"
            style={{
              width: "60%",
              minWidth: "500px",
              height: "97%",
              padding: "0",
              overflow: "auto",
            }}
          >
            <h3
              style={{
                backgroundColor: "gray",
                color: "white",
                margin: 0,
                padding: "5px 0px 5px 20px",
                position: "sticky",
                top: 0,
              }}
            >
              Expense Settlement Information
            </h3>
            {isLoading ? (
              <div style={{ width: "100%", textAlign: "center" }}>
                <img
                  src={loading2}
                  alt="loading..."
                  style={{ width: "50px", opacity: "0.3" }}
                />
              </div>
            ) : (
              <>
                {data != [] && (
                  <div style={{ marginLeft: "50px" }}>
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        Project{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data[2]}
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        Resource{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data[3]}
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        Unit{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data[4]}
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        Unit Price{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {parseFloat(data[5]).toLocaleString() + " Br"}
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        Settled Qty{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {parseFloat(data[6]).toLocaleString()}
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        Settled Amt{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {(
                          parseFloat(data[5]) * parseFloat(data[6])
                        ).toLocaleString() + " Br"}
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        Settled By{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data2 && data2[1] + " " + data2[2] + " " + data2[3]}
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        Document No{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {data[7]}
                      </p>
                    </div>
                    <br />
                    <div
                      className="brdr-btm"
                      style={{
                        width: "100%",
                        display: "inline-flex",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "gray",
                          width: "160px",
                        }}
                      >
                        Settle Date{" "}
                      </p>
                      <p
                        style={{
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        {moment(data[10]).format("DD-MM-YYYY")}
                      </p>
                    </div>
                    <br />
                    <div style={{ display: "inline-flex" }}>
                      {data[13] == empId && (
                        <div
                          className="btn1"
                          style={{
                            width: "150px",
                            textAlign: "center",
                            justifyContent: "center",
                            display: "inline-flex",
                          }}
                          onClick={() => setDeleteWindow(true)}
                        >
                          <FontAwesomeIcon
                            className="menu-icon"
                            id="dlt-icon"
                            style={{
                              padding: "3px",
                              cursor: "pointer",
                              margin: "0",
                              padding: 0,
                              fontSize: "14px",
                            }}
                            icon={faTrashCan}
                          />
                          <p
                            style={{
                              margin: "0",
                              padding: 0,
                              marginLeft: "10px",
                            }}
                          >
                            Delete
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ExpenseSettleDetail;
