import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { expReqAction } from "./state/resourceSlice";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";

function ExpenseRequest() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const tab = useSelector((state) => state.auth.tab);
  const empId = useSelector((state) => state.auth.empId);
  const loggedId = useSelector((state) => state.auth.empId);
  const current_date = moment(Date()).format("DD-MM-YYYY");
  const docNumber2 = useSelector((state) => state.expReq.docNumber);
  const reqId = useSelector((state) => state.expReq.reqId);
  const [data, setData] = useState([]);
  const [docNumber, setDocNumber] = useState(docNumber2);
  const [requestQty, setRequestQty] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [data2, setData2] = useState([]);
  const [totalExpense, setTotalExpense] = useState(0);
  const [remainQty, setRemainQty] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "> Expense > Request";

    if (reqId) {
      axios
        .post("https://www.bdc.et/bdc-api/get_resource_request2.php", {
          req_id: reqId,
        })
        .then((res) => {
          setData(res.data);
          setRemainQty(res.data[0][7]);
        })
        .catch((err) => alert(err));
    }

    if (docNumber != "") {
      setIsLoading2(true);

      axios
        .post("https://www.bdc.et/bdc-api/get_expense_request.php", {
          doc_number: docNumber,
        })
        .then((res) => setData2(res.data))
        .catch((err) => alert(err))
        .finally(() => setIsLoading2(false));
    }
  }, []);

  const OpenDocNumber = () => {
    setIsLoading2(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_expense_request.php", {
        doc_number: docNumber,
      })
      .then((res) => setData2(res.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));

    dispatch(
      expReqAction.setDocNumber({
        docNumber: docNumber,
      })
    );
  };

  const DeleteReq = (id, reqId, docNumber, deleteQty) => {
    if (reqId) {
      axios
        .post("https://www.bdc.et/bdc-api/post_delete_expense_request.php", {
          id: id,
          reqId: reqId,
          docNumber: docNumber,
        })
        .then((res) => {
          setData2(res.data);
          setRemainQty(parseFloat(remainQty) + parseFloat(deleteQty));
        })
        .catch((err) => alert(err));
    }
  };

  const CompareQty = () => {
    if (parseFloat(requestQty) > parseFloat(remainQty)) {
      document.getElementById("requestQty").style.borderColor = "red";
      document.getElementById("requestQtyErr").style.display = "block";
    } else {
      document.getElementById("requestQty").style.borderColor = "lightgray";
      document.getElementById("requestQtyErr").style.display = "none";
    }
    parseFloat(requestQty)
      ? (document.getElementById("requestQty").value =
          parseFloat(requestQty).toLocaleString())
      : (document.getElementById("requestQty").value = "");
  };

  const RegiBtn = () => {
    if (docNumber == "") {
      document.getElementById("docNumber").style.borderColor = "red";
    } else {
      document.getElementById("docNumber").style.borderColor = "lightgray";
    }
    if (requestQty == "") {
      document.getElementById("requestQty").style.borderColor = "red";
    } else {
      document.getElementById("requestQty").style.borderColor = "lightgray";
    }
    if (unitPrice == "") {
      document.getElementById("unitPrice").style.borderColor = "red";
    } else {
      document.getElementById("unitPrice").style.borderColor = "lightgray";
    }
    if (
      parseFloat(requestQty) <= parseFloat(remainQty) &&
      docNumber != "" &&
      parseFloat(unitPrice) != 0
    ) {
      setIsLoading(true);
      axios
        .post("https://www.bdc.et/bdc-api/post_expense_request.php", {
          reqId: reqId,
          proName: data[0][2],
          proId: data[0][1],
          resName: data[0][4],
          resId: data[0][3],
          resType: data[0][12],
          rvNumber: data[0][9],
          resUnit: data[0][5],
          docNumber: docNumber,
          requestQty: requestQty,
          remainQty: parseFloat(data[0][7]) - parseFloat(requestQty),
          unitPrice: unitPrice,
          reqBy: loggedId,
        })
        .then((res) => {
          dispatch(
            expReqAction.setReqId({
              reqId: "",
            })
          );
          setData2(res.data);
          setRemainQty(parseFloat(remainQty) - parseFloat(requestQty));
          document.getElementById("resourceCont").style.display = "none";
        })
        .catch((err) => alert(err))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      {tab.includes("tab41") && (
        <>
          <div
            className="cont-all"
            style={{
              width: "100%",
              minWidth: "500px",
              height: "97%",
              paddingTop: "0",
              overflow: "auto",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "45px",
                backgroundColor: "gray",
                margin: "0",
                display: "inline-flex",
                position: "sticky",
                top: 0,
              }}
            >
              <h2
                style={{
                  margin: "0",
                  color: "white",
                  borderColor: "white",
                  borderRight: "solid",
                  borderWidth: "1px",
                  padding: "10px 20px",
                }}
              >
                Request for Expense
              </h2>
              <p style={{ color: "whitesmoke", marginLeft: "20px" }}>
                Date: {current_date}
              </p>
            </div>
            <div
              className="brdr-btm"
              style={{
                width: "100%",
                marginTop: "0",
                paddingTop: "10px",
                backgroundColor: "rgb(245,245,245)",
                display: "inline-flex",
              }}
            >
              <label style={{ width: "35%", margin: "20px", fontSize: "14px" }}>
                Document Number
                <br />
                <input
                  className="txt-input"
                  id="docNumber"
                  type="text"
                  defaultValue={docNumber2}
                  onChange={(e) => {
                    setDocNumber(e.target.value);
                  }}
                  onBlur={OpenDocNumber}
                  placeholder="Enter Document Number"
                />
              </label>
              <label style={{ width: "35%", margin: "20px", fontSize: "14px" }}>
                Total Request Cost <br />
                <input
                  className="txt-input"
                  id="totalCost"
                  type="text"
                  placeholder="Total Request (Br)"
                  value={
                    data2
                      .reduce((currnetTotal, list) => {
                        return currnetTotal + list[5] * list[6];
                      }, 0)
                      .toLocaleString() + " Br"
                  }
                  disabled
                />
              </label>
              <input
                type="button"
                id="register"
                value={reqId == "" ? "Select Request" : "Change Request"}
                className="btn1"
                style={{
                  width: "35%",
                  height: "37px",
                  marginTop: "41px",
                  marginRight: "20px",
                }}
                onClick={() => navigate("/requested_resource_list", {})}
              />
            </div>
            {/* select request container ================================================================================================ */}
            <div
              className="brdr-btm"
              id="resourceCont"
              style={{
                width: "100%",
                display: reqId ? "block" : "none",
                backgroundColor: "rgb(245,245,245)",
              }}
            >
              <div style={{ display: "inline-flex" }}>
                <label
                  style={{
                    margin: "20px 20px 10px 20px",
                    padding: "0",
                    display: "inline-flex",
                    fontWeight: "bold",
                    color: "gray",
                    fontSize: "14px",
                  }}
                >
                  Project:
                  <p
                    style={{
                      margin: "0 10px",
                      padding: "0",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data != "" ? data[0][2] : ""}
                  </p>
                </label>
                <label
                  style={{
                    margin: "20px 20px 10px 20px",
                    padding: "0",
                    display: "inline-flex",
                    fontWeight: "bold",
                    color: "gray",
                    fontSize: "14px",
                  }}
                >
                  Resource:
                  <p
                    style={{
                      margin: "0 10px",
                      padding: "0",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data != "" ? data[0][4] : ""}
                  </p>
                </label>
                <label
                  style={{
                    margin: "20px 20px 10px 20px",
                    padding: "0",
                    display: "inline-flex",
                    fontWeight: "bold",
                    color: "gray",
                    fontSize: "14px",
                  }}
                >
                  Unit:
                  <p
                    style={{
                      margin: "0 10px",
                      padding: "0",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data != "" ? data[0][5] : ""}
                  </p>
                </label>
                <label
                  style={{
                    margin: "20px 20px 10px 20px",
                    padding: "0",
                    display: "inline-flex",
                    fontWeight: "bold",
                    color: "gray",
                    fontSize: "14px",
                  }}
                >
                  Remaining Qty:
                  <p
                    style={{
                      margin: "0 10px",
                      padding: "0",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    {data != "" ? parseFloat(remainQty).toLocaleString() : ""}
                  </p>
                </label>
              </div>
              <br />
              <div
                style={{
                  width: "100%",
                  display: reqId ? "inline-flex" : "none",
                }}
              >
                <div style={{ width: "25%", margin: "0px 20px" }}>
                  <p
                    id="requestQtyErr"
                    style={{
                      margin: 0,
                      padding: 0,
                      color: "red",
                      fontSize: "14px",
                      paddingLeft: "20px",
                      display: "none",
                    }}
                  >
                    *Over Quantity
                  </p>

                  <input
                    className="txt-input"
                    id="requestQty"
                    type="number"
                    onChange={(e) => {
                      setRequestQty(e.target.value);
                      setTotalPrice(
                        parseFloat(e.target.value) * parseFloat(unitPrice)
                      );
                    }}
                    onBlur={CompareQty}
                    placeholder="Enter Expense Quantity"
                  />
                </div>
                <div style={{ width: "25%", margin: "0px 20px" }}>
                  <input
                    className="txt-input"
                    id="unitPrice"
                    type="number"
                    onChange={(e) => {
                      setUnitPrice(e.target.value);
                      setTotalPrice(
                        parseFloat(requestQty) * parseFloat(e.target.value)
                      );
                    }}
                    placeholder="Enter Unit Price (Before Tax)"
                  />
                </div>
                <div style={{ width: "25%", margin: "0px 20px" }}>
                  <input
                    className="txt-input"
                    id="totalPrice"
                    type="text"
                    placeholder="Total Price (Br)"
                    value={totalPrice ? totalPrice.toLocaleString() : 0}
                    disabled
                  />
                </div>
                <div style={{ width: "25%", margin: "0px 20px" }}>
                  {isLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading1}
                        alt="login..."
                        style={{ width: "80px" }}
                      />
                    </div>
                  ) : (
                    <input
                      type="button"
                      id="register"
                      value="Request"
                      className="btn1"
                      onClick={RegiBtn}
                    />
                  )}
                </div>
              </div>
            </div>

            <table
              style={{
                width: "100%",
                minWidth: "600px",
                marginTop: "20px",
              }}
            >
              <tr
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "gray",
                  position: "sticky",
                  top: "45px",
                }}
              >
                <th>Project</th>
                <th>Resource</th>
                <th>Unit</th>
                <th>Unit Price</th>
                <th>Req. Qty</th>
                <th>Total Price</th>
                <th>Con Doc No</th>
                <th>Req Date</th>
                <th></th>
                <th></th>
              </tr>
              {isLoading2 ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data2 != [] &&
                    data2.map((list) => (
                      <tr key={list[0]}>
                        <td> {list[2]}</td>
                        <td> {list[3]}</td>
                        <td> {list[4]}</td>
                        <td> {parseFloat(list[5]).toLocaleString()}</td>
                        <td> {parseFloat(list[6]).toLocaleString()}</td>
                        <td>
                          {" "}
                          {(
                            parseFloat(list[5]) * parseFloat(list[6])
                          ).toLocaleString()}
                        </td>
                        <td> {list[10]}</td>
                        <td> {moment(list[9]).format("DD-MM-YYYY")}</td>
                        <td>
                          {list[8] == empId && (
                            <FontAwesomeIcon
                              className="menu-icon"
                              id="dlt-icon"
                              style={{
                                padding: "3px",
                                cursor: "pointer",
                                margin: "0",
                                padding: 0,
                                fontSize: "14px",
                                color: "gray",
                              }}
                              icon={faTrashCan}
                              onClick={() =>
                                DeleteReq(list[0], list[1], docNumber, list[6])
                              }
                            />
                          )}
                        </td>
                        <td>
                          <input
                            style={{ cursor: "pointer" }}
                            type="button"
                            value="Open"
                            onClick={() => {
                              navigate("/expense_request_detail", {
                                state: {
                                  id: list[0],
                                },
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </table>
          </div>
        </>
      )}
    </>
  );
}
export default ExpenseRequest;
