import axios from "axios";
import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import loading1 from "../img/loading1.gif";
import loading2 from "../img/loading2.gif";

function NewResource() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const tab = useSelector((state) => state.auth.tab);
  const loggedId = useSelector((state) => state.auth.empId);
  const dep = useSelector((state) => state.auth.dep);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("Civil");
  const [type, setType] = useState("Material");
  const [unit, setUnit] = useState("");
  const [resCategory, setResCategory] = useState("Civil");
  const [resType, setResType] = useState("Material");
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("windowTitle").textContent = "> Resource > Add New";

    setIsLoading2(true);
    axios
      .post("https://www.bdc.et/bdc-api/get_resource_list.php", {
        resType: resType,
        resCategory: resCategory,
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading2(false));
  }, []);

  const RegiBtn = () => {
    if (name == "") {
      document.getElementById("nameErr").style.display = "block";
      document.getElementById("name").style.borderColor = "red";
    } else {
      document.getElementById("nameErr").style.display = "none";
      document.getElementById("name").style.borderColor = "lightgray";
    }
    if (type == "") {
      document.getElementById("typeErr").style.display = "block";
      document.getElementById("type").style.borderColor = "red";
    } else {
      document.getElementById("typeErr").style.display = "none";
      document.getElementById("type").style.borderColor = "lightgray";
    }

    if (name != "" && type != "") {
      setIsLoading(true);
      setIsLoading2(true);
      axios
        .post("https://www.bdc.et/bdc-api/post_new_resource.php", {
          name: name,
          category: category,
          type: type,
          unit: unit,
          resType: resType,
          resCategory: resCategory,
          regBy: loggedId,
        })
        .then((res) => {
          setData(res.data);
          setName("");
          setType("Material");
          setCategory("Civil");
          document.getElementById("name").value = "";
          document.getElementById("successMsg").style.display = "block";
        })
        .catch((err) => alert(err))
        .finally(() => {
          setIsLoading(false);
          setIsLoading2(false);
        });
    }
  };

  return (
    <>
      {tab.includes("tab31") && (
        <>
          <div
            style={{
              display: "inline-flex",
              width: "80%",
              minWidth: "600px",
              height: "97%",
            }}
          >
            <div className="cont-all" style={{ width: "50%" }}>
              <h2
                className="pa-h"
                style={{
                  backgroundColor: "gray",
                  color: "white",
                  margin: 0,
                  padding: "5px 0px",
                  width: "100%",
                }}
              >
                Resource Information
              </h2>
              <p
                className="reg-err"
                id="successMsg"
                style={{
                  color: "green",
                  display: "none",
                  marginTop: "10px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Added Successfully!{" "}
              </p>
              <div style={{ width: "100%" }} id="regCont">
                <div className="cont2">
                  <p className="reg-err" id="nameErr">
                    *This Information is required!
                  </p>
                  <input
                    className="txt-input"
                    id="name"
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder="Enter Resource Name"
                    required
                  />
                  <br />
                  <p className="reg-err" id="typeErr">
                    *This Information is required!
                  </p>
                  <select
                    name="type"
                    id="type"
                    style={{ width: "50%" }}
                    onChange={(e) => {
                      setType(e.target.value);
                      if (e.target.value == "Labor") {
                        setUnit("");
                        document.getElementById("unit").disabled = true;
                      } else if (e.target.value == "Cash") {
                        setUnit("");
                        document.getElementById("unit").disabled = true;
                      } else {
                        document.getElementById("unit").disabled = false;
                      }
                    }}
                    className="txt-input"
                  >
                    <option value="Material">Material</option>
                    <option value="Labor">Labor</option>
                    <option value="Equip & Machine">Equip & Machine</option>
                    <option value="Cash">Cash</option>
                  </select>
                  <br />
                  {type == "Material" && (
                    <select
                      name="type"
                      id="category"
                      onChange={(e) => setCategory(e.target.value)}
                      className="txt-input"
                      style={{ width: "50%" }}
                    >
                      {dep == "Construction Supervision" && (
                        <>
                          <option value="Civil">Civil</option>
                          <option value="Finishing">Finishing</option>
                          <option value="Electrical">Electrical</option>
                          <option value="Sanitary">Sanitary</option>
                        </>
                      )}
                      {dep == "Machinery and Equipment" && (
                        <>
                          <option value="Machinery">Machinery</option>
                        </>
                      )}
                      <option value="Stationary">Stationary</option>
                      <option value="Furniture">Furniture</option>
                    </select>
                  )}
                  <br />
                  <select
                    name="type"
                    id="unit"
                    onChange={(e) => setUnit(e.target.value)}
                    className="txt-input"
                    style={{ width: "50%" }}
                  >
                    <option style={{ color: "gray" }} value="">
                      Select Unit
                    </option>
                    <option value="ls">Lumpsum (ls)</option>
                    <option value="m3">Volume (m3)</option>
                    <option value="m2">Area (m2)</option>
                    <option value="ml">Meter Linear (ml)</option>
                    <option value="qtl">Weight (qtl)</option>
                    <option value="kg">Weight (kg)</option>
                    <option value="pcs">Number (pcs)</option>
                    <option value="pack">Pack (pack)</option>
                    <option value="lit">Volume (lit)</option>
                    <option value="gal">Volume (gal)</option>
                    <option value="hr">Time (hr)</option>
                  </select>

                  <br />

                  {isLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <img
                        src={loading1}
                        alt="login..."
                        style={{ width: "100px" }}
                      />
                    </div>
                  ) : (
                    <input
                      type="button"
                      id="register"
                      value="Register"
                      className="btn1"
                      style={{ margin: "20px 0", width: "50%" }}
                      onClick={RegiBtn}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="brdr-all"
              style={{
                width: "50%",
                backgroundColor: "white",
                marginLeft: "20px",
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  position: "sticky",
                  top: 0,
                }}
              >
                <h2
                  className="pa-h"
                  style={{
                    backgroundColor: "gray",
                    color: "white",
                    margin: 0,
                    padding: "5px 0px",
                    width: "100%",
                  }}
                >
                  Resource List
                </h2>
                <div
                  className="brdr-btm"
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(240,240,240)",
                    marginTop: "0px",
                  }}
                >
                  <div style={{ display: "inline-flex", width: "100%" }}>
                    <label
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        color: "gray",
                        width: "50%",
                      }}
                    >
                      Resource Type
                      <br />
                      <select
                        name="type"
                        id="type"
                        onChange={(e) => {
                          setResType(e.target.value);
                          axios
                            .post(
                              "https://www.bdc.et/bdc-api/get_resource_list.php",
                              {
                                resType: e.target.value,
                                resCategory: resCategory,
                              }
                            )
                            .then((res) => {
                              setData(res.data);
                            });
                        }}
                        className="txt-input"
                      >
                        <option value="Material">Material</option>
                        <option value="Labor">Labor</option>
                        <option value="Equip & Machine">Equip & Machine</option>
                        <option value="Cash">Cash</option>
                      </select>
                    </label>
                    {resType == "Material" && (
                      <label
                        style={{
                          fontSize: "14px",
                          color: "gray",
                          marginLeft: "10px",
                          width: "50%",
                        }}
                      >
                        Resource Category
                        <br />
                        <select
                          name="type"
                          id="category"
                          onChange={(e) => {
                            setResCategory(e.target.value);
                            setIsLoading2(true);
                            axios
                              .post(
                                "https://www.bdc.et/bdc-api/get_resource_list.php",
                                {
                                  resType: resType,
                                  resCategory: e.target.value,
                                }
                              )
                              .then((res) => {
                                setData(res.data);
                              })
                              .catch((err) => alert(err))
                              .finally(() => setIsLoading2(false));
                          }}
                          className="txt-input"
                        >
                          <option value="Civil">Civil</option>
                          <option value="Finishing">Finishing</option>
                          <option value="Electrical">Electrical</option>
                          <option value="Sanitary">Sanitary</option>
                          <option value="Stationary">Stationary</option>
                          <option value="Furniture">Furniture</option>
                          <option value="Machinery">Machinery</option>
                        </select>
                      </label>
                    )}
                    <br />
                  </div>
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "0",
                      paddingLeft: "20px",
                    }}
                  >
                    {data.length + " "}Records
                  </p>
                </div>
              </div>
              {isLoading2 ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={loading2}
                    alt="loading..."
                    style={{ width: "50px", opacity: "0.3" }}
                  />
                </div>
              ) : (
                <>
                  {data != [] &&
                    data.map((list) => (
                      <div
                        className="brdr-btm list-hover"
                        key={list[0]}
                        style={{ padding: "10px 20px", cursor: "pointer" }}
                        onClick={() =>
                          navigate("/resource_detail", {
                            state: {
                              id: list[0],
                            },
                          })
                        }
                      >
                        <h3 style={{ margin: 0, color: "gray" }}>{list[1]}</h3>
                        <p style={{ margin: 0 }}>{list[3]}</p>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NewResource;
