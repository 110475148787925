import { React } from "react";
import { useSelector } from "react-redux";
import EmpDashboardHr from "./empDashboardHr";
import EmpDashboardSupervision from "./empDashboardSupervision";
import EmpDashboardContract from "./empDashboardContract";
import EmpDashboardFinance from "./empDashboardFinance";
import EmpDashboardPurchase from "./empDashboardPurchase";
import EmpDashboardMachinery from "./empDashboardMachinery";

function EmpDashboard() {
  const dep = useSelector((state) => state.auth.dep);

  return (
    <>
      {dep == "Construction Supervision" && <EmpDashboardMachinery />}
      {dep == "Contract Administration" && <EmpDashboardContract />}
      {dep == "Human Resource" && <EmpDashboardHr />}
      {dep == "Finance" && <EmpDashboardFinance />}
      {dep == "Purchase and Logistics" && <EmpDashboardPurchase />}
      {dep == "Machinery and Equipment" && <EmpDashboardMachinery />}
      {dep == "General Manager" && <EmpDashboardFinance />}
    </>
  );
}

export default EmpDashboard;
